import React from "react"
import { graphql } from "gatsby"
import { useQueryParam, NumberParam, StringParam } from "use-query-params"

import Img from "gatsby-image"

import theme from "../themes/theme"
import * as EmailValidator from "email-validator"

import { PageLayout } from "../components/PageLayout"

import {
  // HeroGrid,
  // HeroSection,
  HeroHeadline,
  HeroSubHead,
  // HeroExtra,
  // HeroButton,
} from "../components/HeroSection"

import { FormButtonSecondary } from "../components/Buttons"

import {
  RowSection,
  TwoColumnSection,
  TwoColumnSectionLeft,
  TwoColumnSectionRight,
} from "../components/SectionBlocks"

import { FaRegUser, FaMoneyCheckAlt } from "react-icons/fa"
import {
  AiOutlineMail,
  AiOutlineMobile,
  AiOutlineClockCircle,
  AiOutlineQuestionCircle,
} from "react-icons/ai"
import { GoDeviceMobile } from "react-icons/go"
import { FiTarget } from "react-icons/fi"
import { BiCurrentLocation, BiHelpCircle, BiStopwatch } from "react-icons/bi"
import { BsPersonCheck } from "react-icons/bs"
import { IoMaleFemale } from "react-icons/io5"

// import Bio from "../components/bio"
// import Layout from "../components/layout"
import SEO from "../components/seo"
import { Fragment } from "react"
// import { render } from "react-dom"

const PageClubBunker = ({ data, location }) => {
  const queryString = location.search
  const urlParams = new URLSearchParams(queryString)
  const referringCoach = urlParams.get("coach")
  const visitorGoal = urlParams.get("goal")
  console.log("location raw:\n" + location)
  console.log("location json:\n" + JSON.stringify(location))
  console.log("location.search\n" + location.search)
  console.log("goal: " + visitorGoal)
  console.log("coach: " + referringCoach)

  // const siteTitle = data.site.siteMetadata?.title || `Title`
  // const posts = data.allMarkdownRemark.nodes

  // if (posts.length === 0) {
  //   return (
  //     <Layout location={location} title={siteTitle}>
  //       <SEO title="All posts" />
  //       <Bio />
  //       <p>
  //         No blog posts found. Add markdown posts to "content/blog" (or the directory you specified
  //         for the "gatsby-source-filesystem" plugin in gatsby-config.js).
  //       </p>
  //     </Layout>
  //   )
  // }

  // const [fname, setFname] = useQueryParam("f", StringParam)
  // const [email, setEmail] = useQueryParam("e", StringParam)
  // const [mobile, setMobile] = useQueryParam("m", StringParam)

  return (
    <PageLayout>
      <SEO
        title="Club Bunker - Your fitness goals"
        ogImage={data?.socialImage?.childImageSharp?.fixed.src}
        description="Helping Club Bunker members achieve their best"
        url={location.origin + location.pathname}
      />
      <RowSection
        rowMargin="0%"
        backgroundUrl={data?.handstandImage?.childImageSharp?.fixed}
        bgX="60%"
        bgY="0%"
        // overlay="rgba(255, 255, 255, 0.8)"
        // overlay="linear-gradient(90deg, rgba(0,0,0,1) 0%, rgba(0,0,0,0.5) 10%)"
        // opacity="0.9">
      >
        <TwoColumnSection columnLeftWidth="50%" columnRightWidth="50%" sideMargin="5%">
          <TwoColumnSectionLeft>
            <div
              style={{
                borderRadius: "12px",
                height: "100%",
                marginTop: "5%",
                marginBottom: "5%",
                backgroundColor: theme.colors.primaryBlueNormal,
                marginLeft: "5%",
                marginRight: "5%",
              }}>
              <HeroHeadline>
                <h1
                  style={{
                    color: theme.colors.white,
                    textAlign: "center",
                    align: "center",
                    padding: "10px",
                    lineHeight: theme.spacings.large,
                  }}>
                  Start Getting Leaner, Fitter, Stronger Today
                </h1>
              </HeroHeadline>
              <HeroSubHead>
                <div style={{ paddingLeft: "20px", paddingRight: "20px" }}>
                  <p
                    style={{
                      color: theme.colors.light1,
                      textAlign: "center",
                      paddingLeft: "10px",
                      paddingRight: "10px",
                    }}>
                    As a new <strong>Club Bunker</strong> member you are eligible for a{" "}
                    <i>FREE CONSULT</i> with one of our qualified Coaches.
                  </p>
                  <p
                    style={{
                      color: theme.colors.light1,
                      textAlign: "center",
                      paddingLeft: "10px",
                      paddingRight: "10px",
                    }}>
                    To ensure we deliver the very best gym and fitness experience, we'd love to get
                    to know you a little better.
                  </p>
                  <p
                    style={{
                      color: theme.colors.light1,
                      textAlign: "center",
                      paddingLeft: "10px",
                      paddingRight: "10px",
                    }}>
                    Simply complete this short questionnaire and book in for your free consultation.
                  </p>
                  <p
                    style={{
                      color: theme.colors.light1,
                      textAlign: "center",
                      paddingLeft: "10px",
                      paddingRight: "10px",
                    }}>
                    No going back and forth to schedule a time that works for you and the coach,
                    secure time directly in their calendar.
                  </p>
                  <p
                    style={{
                      color: theme.colors.light1,
                      textAlign: "center",
                      paddingLeft: "10px",
                      paddingRight: "10px",
                    }}>
                    There are <strong>no obligations</strong> other than we request if you are
                    unable to make the appointment, please let us know beforehand.
                  </p>
                </div>

                {/* <div className="kartra_optin_containereccbc87e4b5ce2fe28308fd9f2a7baf3"></div>
                <script src="https://app.kartra.com/optin/TcloDUQyO3a4"></script> */}

                {/* <QualificationForm fname={fname} email={email} mobile={mobile} /> */}
                <QualificationForm />
                <p></p>
                <p
                  style={{
                    color: theme.colors.light3,
                    textAlign: "center",
                    paddingLeft: "10px",
                    paddingRight: "10px",
                    fontSize: theme.spacings.mSmall,
                  }}>
                  Your information is completely safe. We do not sell your contact details or share
                  your personal information.
                </p>
                <p
                  style={{
                    color: theme.colors.light3,
                    textAlign: "center",
                    paddingLeft: "10px",
                    paddingRight: "10px",
                    fontSize: theme.spacings.mSmall,
                  }}>
                  We also really hate spam too!
                </p>
              </HeroSubHead>
            </div>
          </TwoColumnSectionLeft>
          <TwoColumnSectionRight alignItems="center" justifyContent="flex-start">
            <HeroHeadline>
              <h1
                style={{
                  color: theme.colors.white,
                  textAlign: "center",
                  paddingTop: "50px",
                }}>
                Get your FREE consult
                <Img fluid={data?.clubBunkerLogo?.childImageSharp?.fluid} alt="Club Bunker logo" />
              </h1>
              <h2 style={{ color: theme.colors.primaryOrangeNormal, textAlign: "center" }}>
                Starting at a new gym and aren't really sure where to start?
                <br />
                <br />
                We know exactly how you feel! We are more than happy to spend time helping you get
                your questions answered.
              </h2>
            </HeroHeadline>
            <HeroSubHead>
              <p style={{ color: theme.colors.light1, textAlign: "center" }}>
                Not sure where to start? Have a previous injury you're concerned about? Tried a gym
                or trainer before and it didn't work out?
              </p>
              <p style={{ color: theme.colors.light1, textAlign: "center" }}>
                Want to learn more about our coaches and training programs?
              </p>
              <p style={{ color: theme.colors.light1, textAlign: "center" }}>
                We know exactly how you feel! We are more than happy to spend time helping you get
                your questions answered.
              </p>
            </HeroSubHead>
          </TwoColumnSectionRight>
        </TwoColumnSection>
      </RowSection>
    </PageLayout>

    // <PageLayout>
    //   <SEO title="Schedule Free Assessment" />
    //   <Layout location={location} title={siteTitle}>

    //     <Bio />
    //     <ol style={{ listStyle: `none` }}>
    //       {posts.map((post) => {
    //         const title = post.frontmatter.title || post.fields.slug

    //         return (
    //           <li key={post.fields.slug}>
    //             <article className="post-list-item" itemScope itemType="http://schema.org/Article">
    //               <header>
    //                 <h2>
    //                   <Link to={post.fields.slug} itemProp="url">
    //                     <span itemProp="headline">{title}</span>
    //                   </Link>
    //                 </h2>
    //                 <small>{post.frontmatter.date}</small>
    //               </header>
    //               <section>
    //                 <p
    //                   dangerouslySetInnerHTML={{
    //                     __html: post.frontmatter.description || post.excerpt,
    //                   }}
    //                   itemProp="description"
    //                 />
    //               </section>
    //             </article>
    //           </li>
    //         )
    //       })}
    //     </ol>
    //   </Layout>
    // </PageLayout>
  )
}

export default PageClubBunker

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      nodes {
        excerpt
        fields {
          slug
        }
        frontmatter {
          date(formatString: "MMMM DD, YYYY")
          title
          description
        }
      }
    }
    handstandImage: file(absolutePath: { regex: "/mitch-handstand-dark-1920x874.jpg/" }) {
      childImageSharp {
        fixed(width: 1200, height: 874, quality: 95) {
          ...GatsbyImageSharpFixed_withWebp
        }
        fluid(maxWidth: 1200) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }

    clubBunkerLogo: file(absolutePath: { regex: "/ClubBunkerLogo.png/" }) {
      childImageSharp {
        fluid(maxWidth: 500) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }

    heroImage: file(absolutePath: { regex: "/mitch-squat-light-1920x874.jpg/" }) {
      childImageSharp {
        fixed(width: 1920, height: 874, quality: 95) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
    socialImage: file(
      absolutePath: { regex: "/mitch-alex-bunker-wall-logo-social-1200x628.jpg/" }
    ) {
      childImageSharp {
        fixed(width: 1200, height: 628, quality: 95) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
  }
`

class BigGoalComponent extends React.Component {
  constructor(props) {
    super(props)
    this.handleValueChange = this.handleValueChange.bind(this)
    // if (this.props.fname) {
    //   this.value = this.props.fname
    // }
  }

  handleValueChange(e) {
    this.props.onBigGoalChange(e.target.value)
  }

  render() {
    return (
      <div
        style={{
          alignItems: "flex-start",
          justifyContent: "center",
          display: "flex",
          flexDirection: "row",
          // border: "1px solid white",
          margin: "10px",
          padding: "10px",
        }}>
        <div
          style={{
            paddingRight: "5px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}>
          <label htmlFor="custom_9">
            <FiTarget color="white" fontSize="2.3rem" />
          </label>
        </div>
        <div style={{ width: "70%" }}>
          <select
            type="text"
            name="custom_9"
            className="js_kartra_santitation"
            data-santitation-type="name"
            id="custom_9"
            aria-label="What is your big goal?"
            style={{
              width: "100%",
              height: "3rem",
              border: this.props.goalBorder,
              borderRadius: "5px",
              padding: "5px",
            }}
            onChange={this.handleValueChange}>
            <option value="0" disabled selected>
              What is your big goal right now?
            </option>
            <option value="16">Get stronger and more muscular</option>
            <option value="17">Get leaner and more defined</option>
            <option value="18">Get flexible and move better</option>
          </select>
        </div>
      </div>
    )
  }
}

class FitnessSituationComponent extends React.Component {
  constructor(props) {
    super(props)
    this.handleValueChange = this.handleValueChange.bind(this)
    // if (this.props.fname) {
    //   this.value = this.props.fname
    // }
  }

  handleValueChange(e) {
    this.props.onFitnessSituationChange(e.target.value)
  }

  render() {
    return (
      <div
        style={{
          alignItems: "flex-start",
          justifyContent: "center",
          display: "flex",
          flexDirection: "row",
          // border: "1px solid white",
          margin: "10px",
          padding: "10px",
        }}>
        <div
          style={{
            paddingRight: "5px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}>
          <label htmlFor="custom_10">
            <BiCurrentLocation color="white" fontSize="2.3rem" />
          </label>
        </div>
        <div style={{ width: "70%" }}>
          <select
            type="text"
            name="custom_10"
            className="js_kartra_santitation"
            data-santitation-type="name"
            id="custom_10"
            aria-label="What is your fitness situation"
            style={{
              width: "100%",
              height: "3rem",
              border: this.props.fitnessSituationBorder,
              borderRadius: "5px",
              padding: "5px",
            }}
            onChange={this.handleValueChange}>
            <option value="0" disabled selected>
              What best describes your current fitness situation?
            </option>
            <option value="19">
              I previously achieved my goal but have lost my way and want help to get back to where
              I was
            </option>
            <option value="20">I am partway to achieving my goal but now I&#039;m stuck</option>
            <option value="21">
              I&#039;ve always struggled with my goal and have no idea what to do
            </option>
          </select>
        </div>
      </div>
    )
  }
}

class WhenStartComponent extends React.Component {
  constructor(props) {
    super(props)
    this.handleValueChange = this.handleValueChange.bind(this)
    // if (this.props.fname) {
    //   this.value = this.props.fname
    // }
  }

  handleValueChange(e) {
    this.props.onWhenStartChange(e.target.value)
  }

  render() {
    return (
      <div
        style={{
          alignItems: "flex-start",
          justifyContent: "center",
          display: "flex",
          flexDirection: "row",
          // border: "1px solid white",
          margin: "10px",
          padding: "10px",
        }}>
        <div
          style={{
            paddingRight: "5px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}>
          <label htmlFor="custom_11">
            <AiOutlineClockCircle color="white" fontSize="2.3rem" />
          </label>
        </div>
        <div style={{ width: "70%" }}>
          <select
            type="text"
            name="custom_11"
            className="js_kartra_santitation"
            data-santitation-type="name"
            id="custom_11"
            aria-label="What is your fitness situation"
            style={{
              width: "100%",
              height: "3rem",
              border: this.props.whenStartBorder,
              borderRadius: "5px",
              padding: "5px",
            }}
            onChange={this.handleValueChange}>
            <option value="0" disabled selected>
              When are you looking to start?
            </option>
            <option value="22">Now</option>
            <option value="23">Later</option>
          </select>
        </div>
      </div>
    )
  }
}

class WhatExtraHelpComponent extends React.Component {
  constructor(props) {
    super(props)
    this.handleValueChange = this.handleValueChange.bind(this)
    // if (this.props.fname) {
    //   this.value = this.props.fname
    // }
  }

  handleValueChange(e) {
    this.props.onWhatExtraHelpChange(e.target.value)
  }

  render() {
    return (
      <div
        style={{
          alignItems: "flex-start",
          justifyContent: "center",
          display: "flex",
          flexDirection: "row",
          // border: "1px solid white",
          margin: "10px",
          padding: "10px",
        }}>
        <div
          style={{
            paddingRight: "5px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}>
          <label htmlFor="custom_12">
            <BiHelpCircle color="white" fontSize="2.3rem" />
          </label>
        </div>
        <div style={{ width: "70%" }}>
          <textarea
            type="text"
            name="custom_12"
            className="js_kartra_santitation"
            data-santitation-type="name"
            id="custom_12"
            cols="50"
            rows="5"
            aria-label="What do you need the most help with right now?"
            placeholder="What do you need the most help with right now?"
            style={{
              width: "100%",
              height: "3rem",
              border: this.props.extraHelpBorder,
              borderRadius: "5px",
              padding: "5px",
            }}
            onChange={this.handleValueChange}></textarea>
        </div>
      </div>
    )
  }
}

class GoalTimelineComponent extends React.Component {
  constructor(props) {
    super(props)
    this.handleValueChange = this.handleValueChange.bind(this)
    // if (this.props.fname) {
    //   this.value = this.props.fname
    // }
  }

  handleValueChange(e) {
    this.props.onGoalTimelineChange(e.target.value)
  }

  render() {
    return (
      <div
        style={{
          alignItems: "flex-start",
          justifyContent: "center",
          display: "flex",
          flexDirection: "row",
          // border: "1px solid white",
          margin: "10px",
          padding: "10px",
        }}>
        <div
          style={{
            paddingRight: "5px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}>
          <label htmlFor="custom_13">
            <BiStopwatch color="white" fontSize="2.3rem" />
          </label>
        </div>
        <div style={{ width: "70%" }}>
          <select
            type="text"
            name="custom_13"
            className="js_kartra_santitation"
            data-santitation-type="name"
            id="custom_13"
            aria-label="What is your goal timeline"
            style={{
              width: "100%",
              height: "3rem",
              border: this.props.goalTimelineBorder,
              borderRadius: "5px",
              padding: "5px",
            }}
            onChange={this.handleValueChange}>
            <option value="0" disabled selected>
              What do you believe is a realistic timeline to achieve your goal?
            </option>
            <option value="24">12 months or greater</option>
            <option value="25">6 - 12 months</option>
            <option value="26">Less than 6 months</option>
          </select>
        </div>
      </div>
    )
  }
}

class DecisionMakerComponent extends React.Component {
  constructor(props) {
    super(props)
    this.handleValueChange = this.handleValueChange.bind(this)
    // if (this.props.fname) {
    //   this.value = this.props.fname
    // }
  }

  handleValueChange(e) {
    this.props.onDecisionMakerChange(e.target.value)
  }

  render() {
    return (
      <div
        style={{
          alignItems: "flex-start",
          justifyContent: "center",
          display: "flex",
          flexDirection: "row",
          // border: "1px solid white",
          margin: "10px",
          padding: "10px",
        }}>
        <div
          style={{
            paddingRight: "5px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}>
          <label htmlFor="custom_14">
            <BsPersonCheck color="white" fontSize="2.3rem" />
          </label>
        </div>
        <div style={{ width: "70%" }}>
          <select
            type="text"
            name="custom_14"
            className="js_kartra_santitation"
            data-santitation-type="name"
            id="custom_14"
            aria-label="Are you the decision maker?"
            style={{
              width: "100%",
              height: "3rem",
              border: this.props.decisionMakerBorder,
              borderRadius: "5px",
              padding: "5px",
            }}
            onChange={this.handleValueChange}>
            <option value="0" disabled selected>
              Are you the financial decision maker?
            </option>
            <option value="27">Yes</option>
            <option value="28">No, I need to consult with my partner / significant other</option>
          </select>
        </div>
      </div>
    )
  }
}

class FinancialSituationComponent extends React.Component {
  constructor(props) {
    super(props)
    this.handleValueChange = this.handleValueChange.bind(this)
    // if (this.props.fname) {
    //   this.value = this.props.fname
    // }
  }

  handleValueChange(e) {
    this.props.onFinancialSituationChange(e.target.value)
  }

  render() {
    return (
      <div
        style={{
          alignItems: "flex-start",
          justifyContent: "center",
          display: "flex",
          flexDirection: "row",
          // border: "1px solid white",
          margin: "10px",
          padding: "10px",
        }}>
        <div
          style={{
            paddingRight: "5px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}>
          <label htmlFor="custom_15">
            <FaMoneyCheckAlt color="white" fontSize="2.3rem" />
          </label>
        </div>
        <div style={{ width: "70%" }}>
          <select
            type="text"
            name="custom_15"
            className="js_kartra_santitation"
            data-santitation-type="name"
            id="custom_15"
            aria-label="What best describes your current financial situation?"
            style={{
              width: "100%",
              height: "3rem",
              border: this.props.financialSituationBorder,
              borderRadius: "5px",
              padding: "5px",
            }}
            onChange={this.handleValueChange}>
            <option value="0" disabled selected>
              What best describes your financial situation?
            </option>
            <option value="29">
              I have the financial resources now to invest in my health and fitness
            </option>
            <option value="30">
              I am broke and have no credit card to invest in my health and fitness
            </option>
          </select>
        </div>
      </div>
    )
  }
}

class PreferredTrainerGenderComponent extends React.Component {
  constructor(props) {
    super(props)
    this.handleValueChange = this.handleValueChange.bind(this)
    // if (this.props.fname) {
    //   this.value = this.props.fname
    // }
  }

  handleValueChange(e) {
    this.props.onPreferredTrainerGenderChange(e.target.value)
  }

  render() {
    return (
      <div
        style={{
          alignItems: "flex-start",
          justifyContent: "center",
          display: "flex",
          flexDirection: "row",
          // border: "1px solid white",
          margin: "10px",
          padding: "10px",
        }}>
        <div
          style={{
            paddingRight: "5px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}>
          <label htmlFor="custom_17">
            <IoMaleFemale color="white" fontSize="2.3rem" />
          </label>
        </div>
        <div style={{ width: "70%" }}>
          <select
            type="text"
            name="custom_17"
            className="js_kartra_santitation"
            data-santitation-type="name"
            id="custom_17"
            aria-label="Do you have a preferred trainer gender?"
            style={{
              width: "100%",
              height: "3rem",
              border: this.props.preferredTrainerGenderBorder,
              borderRadius: "5px",
              padding: "5px",
            }}
            onChange={this.handleValueChange}>
            <option value="0" disabled selected>
              Do you have a preferred trainer gender
            </option>

            <option value="33">No preference</option>
            <option value="31">Male</option>
            <option value="32">Female</option>
          </select>
        </div>
      </div>
    )
  }
}

class AnythingElseToShareComponent extends React.Component {
  constructor(props) {
    super(props)
    this.handleValueChange = this.handleValueChange.bind(this)
    // if (this.props.fname) {
    //   this.value = this.props.fname
    // }
  }

  handleValueChange(e) {
    this.props.onAnythingElseToShareChange(e.target.value)
  }

  render() {
    return (
      <div
        style={{
          alignItems: "flex-start",
          justifyContent: "center",
          display: "flex",
          flexDirection: "row",
          // border: "1px solid white",
          margin: "10px",
          padding: "10px",
        }}>
        <div
          style={{
            paddingRight: "5px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}>
          <label htmlFor="custom_16">
            <AiOutlineQuestionCircle color="white" fontSize="2.3rem" />
          </label>
        </div>
        <div style={{ width: "70%" }}>
          <textarea
            type="text"
            name="custom_16"
            className="js_kartra_santitation"
            data-santitation-type="name"
            id="custom_16"
            cols="50"
            rows="5"
            aria-label="Is there anything else you feel is important and want to share?"
            placeholder="Is there anything else you feel is important and want to share?"
            style={{
              width: "100%",
              height: "3rem",
              border: this.props.anythingElseToShareBorder,
              borderRadius: "5px",
              padding: "5px",
            }}
            onChange={this.handleValueChange}></textarea>
        </div>
      </div>
    )
  }
}

class UserNameComponent extends React.Component {
  constructor(props) {
    super(props)
    this.handleValueChange = this.handleValueChange.bind(this)
    // if (this.props.fname) {
    //   this.value = this.props.fname
    // }
  }

  handleValueChange(e) {
    this.props.onUserNameChange(e.target.value)
  }

  render() {
    return (
      <div
        style={{
          alignItems: "flex-start",
          justifyContent: "center",
          display: "flex",
          flexDirection: "row",
          // border: "1px solid white",
          margin: "10px",
          padding: "10px",
        }}>
        <div
          style={{
            paddingRight: "5px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}>
          <label htmlFor="userName">
            <FaRegUser color="white" fontSize="2.3rem" />
          </label>
        </div>
        <div style={{ width: "70%" }}>
          <input
            type="text"
            name="first_name"
            placeholder={"First name ..."}
            // defaultValue={this.props.fname ? this.props.fname : ""}
            // value=""
            className="js_kartra_santitation"
            data-santitation-type="name"
            id="userName"
            aria-label="First Name"
            style={{
              width: "100%",
              height: "3rem",
              border: this.props.userBorder,
              borderRadius: "5px",
              padding: "5px",
            }}
            onChange={this.handleValueChange}
          />
        </div>
      </div>
    )
  }
}

class UserEmailComponent extends React.Component {
  constructor(props) {
    super(props)
    this.handleValueChange = this.handleValueChange.bind(this)
  }

  handleValueChange(e) {
    this.props.onUserEmailChange(e.target.value)
  }

  render() {
    return (
      <div
        style={{
          alignItems: "flex-start",
          justifyContent: "center",
          display: "flex",
          flexDirection: "row",
          border: "0px solid white",
          margin: "10px",
          padding: "10px",
        }}>
        <div
          style={{
            paddingRight: "5px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}>
          <label htmlFor="userEmail">
            <AiOutlineMail color="white" fontSize="2.3rem" padding="5px" />
          </label>
        </div>
        <div style={{ width: "70%" }}>
          <input
            type="text"
            className="js_kartra_santitation"
            data-santitation-type="email"
            placeholder="Email address ..."
            // defaultValue={this.props.email ? this.props.email : ""}
            name="email"
            id="userEmail"
            aria-label="Email Address"
            onChange={this.handleValueChange}
            // style={{ border: "1px solid white", padding: "5px" }}
            style={{
              width: "100%",
              height: "3rem",
              border: this.props.emailBorder,
              borderRadius: "5px",
              padding: "5px",
            }}
          />
        </div>
      </div>
    )
  }
}

class UserMobileComponent extends React.Component {
  constructor(props) {
    super(props)
    this.handleValueChange = this.handleValueChange.bind(this)
  }

  handleValueChange(e) {
    this.props.onUserMobileChange(e.target.value)
  }

  render() {
    return (
      <div
        style={{
          alignItems: "flex-start",
          justifyContent: "center",
          display: "flex",
          flexDirection: "row",
          border: "0px solid white",
          margin: "10px",
          padding: "10px",
        }}>
        <div
          style={{
            paddingRight: "5px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}>
          <label htmlFor="userMobile">
            <GoDeviceMobile color="white" fontSize="2.3rem" padding="5px" />
          </label>
        </div>
        <div style={{ width: "70%" }}>
          <input
            type="text"
            className="js_kartra_santitation"
            data-santitation-type="numeric"
            placeholder="Mobile phone ..."
            // defaultValue={this.props.mobile ? this.props.mobile : ""}
            name="phone"
            id="userMobile"
            aria-label="Mobile Phone"
            onChange={this.handleValueChange}
            // style={{ border: "1px solid white", padding: "5px" }}
            style={{
              width: "100%",
              height: "3rem",
              border: this.props.mobileBorder,
              borderRadius: "5px",
              padding: "5px",
            }}
          />
        </div>
      </div>
    )
  }
}

class QualificationForm extends React.Component {
  constructor(props) {
    super(props)
    this.handleUserNameChange = this.handleUserNameChange.bind(this)
    this.handleUserEmailChange = this.handleUserEmailChange.bind(this)
    this.handleUserMobileChange = this.handleUserMobileChange.bind(this)
    this.handleBigGoalChange = this.handleBigGoalChange.bind(this)
    this.handleFitnessSituationChange = this.handleFitnessSituationChange.bind(this)
    this.handleWhenStartChange = this.handleWhenStartChange.bind(this)
    this.handleWhatExtraHelpChange = this.handleWhatExtraHelpChange.bind(this)
    this.handleGoalTimelineChange = this.handleGoalTimelineChange.bind(this)
    this.handleDecisionMakerChange = this.handleDecisionMakerChange.bind(this)
    this.handleFinancialSituationChange = this.handleFinancialSituationChange.bind(this)
    this.handlePreferredTrainerGenderChange = this.handlePreferredTrainerGenderChange.bind(this)
    this.handleAnythingElseToShareChange = this.handleAnythingElseToShareChange.bind(this)

    this.state = {
      validName: false,
      userBorder: "none",
      validEmail: false,
      emailBorder: "none",
      validMobile: false,
      mobileBorder: "none",
      validGoal: false,
      goalBorder: "none",
      validFitnessSituation: false,
      fitnessSituationBorder: "none",
      validWhenStart: false,
      whenStartBorder: "none",
      validExtraHelp: true,
      extraHelpBorder: "none",
      validGoalTimeline: false,
      goalTimelineBorder: "none",
      validDecisionMaker: false,
      decisionMakerBorder: "none",
      validFinancialSituation: false,
      financialSituationBorder: "none",
      validPreferredTrainerGender: false,
      preferredTrainerGenderBorder: "none",
      vallidAnythingElseToShare: false,
      anythingElseToShareBorder: "none",
    }
  }

  handleUserNameChange(e) {
    if (e.length > 2) {
      this.setState({ validName: true, userBorder: "2px solid green" })
    } else {
      this.setState({ validName: false, userBorder: "2px solid red" })
    }
  }

  handleUserEmailChange(e) {
    if (EmailValidator.validate(e)) {
      this.setState({ validEmail: true, emailBorder: "2px solid green" })
    } else {
      this.setState({ validEmail: false, emailBorder: "2px solid red" })
    }
  }

  handleUserMobileChange(e) {
    if (e.length > 9) {
      this.setState({ validMobile: true, mobileBorder: "2px solid green" })
    } else {
      this.setState({ validMobile: false, mobileBorder: "2px solid red" })
    }
  }

  handleBigGoalChange(e) {
    if (e > 0) {
      this.setState({ validGoal: true, goalBorder: "2px solid green" })
    } else {
      this.setState({ validGoal: false, goalBorder: "2px solid red" })
    }
  }

  handleFitnessSituationChange(e) {
    if (e > 0) {
      this.setState({ validFitnessSituation: true, fitnessSituationBorder: "2px solid green" })
    } else {
      this.setState({ validFitnessSituation: false, fitnessSituationBorder: "2px solid red" })
    }
  }

  handleWhenStartChange(e) {
    if (e > 0) {
      this.setState({ validWhenStart: true, whenStartBorder: "2px solid green" })
    } else {
      this.setState({ validWhenStart: false, whenStartBorder: "2px solid red" })
    }
  }

  handleWhatExtraHelpChange(e) {
    if (e.length >= 0) {
      this.setState({ validExtraHelp: true, extraHelpBorder: "2px solid green" })
    } else {
      this.setState({ validExtraHelp: false, extraHelpBorder: "2px solid red" })
    }
  }

  handleGoalTimelineChange(e) {
    if (e > 0) {
      this.setState({ validGoalTimeline: true, goalTimelineBorder: "2px solid green" })
    } else {
      this.setState({ validGoalTimeline: false, goalTimelineBorder: "2px solid red" })
    }
  }

  handleDecisionMakerChange(e) {
    if (e > 0) {
      this.setState({ validDecisionMaker: true, decisionMakerBorder: "2px solid green" })
    } else {
      this.setState({ validDecisionMaker: false, decisionMakerBorder: "2px solid red" })
    }
  }

  handleFinancialSituationChange(e) {
    if (e > 0) {
      this.setState({ validFinancialSituation: true, financialSituationBorder: "2px solid green" })
    } else {
      this.setState({ validFinancialSituation: false, financialSituationBorder: "2px solid red" })
    }
  }

  handlePreferredTrainerGenderChange(e) {
    if (e > 0) {
      this.setState({
        validPreferredTrainerGender: true,
        preferredTrainerGenderBorder: "2px solid green",
      })
    } else {
      this.setState({
        validPreferredTrainerGender: false,
        preferredTrainerGenderBorder: "2px solid red",
      })
    }
  }

  handleAnythingElseToShareChange(e) {
    if (e.length >= 0) {
      this.setState({
        vallidAnythingElseToShare: true,
        anythingElseToShareBorder: "2px solid green",
      })
    } else {
      this.setState({
        vallidAnythingElseToShare: false,
        anythingElseToShareBorder: "2px solid red",
      })
    }
  }

  render() {
    return (
      <div>
        <form
          method="post"
          action="https://app.kartra.com/process/add_lead/I8SYHKXsBFa4"
          target="_top"
          className="form_class_TcloDUQyO3a4 optin_form_for_video js_kartra_trackable_object"
          data-kt-type="optin"
          data-kt-value="I8SYHKXsBFa4"
          data-kt-owner="Brl61K8g"
          acceptCharset="UTF-8">
          <div>
            <BigGoalComponent
              onBigGoalChange={this.handleBigGoalChange}
              goalBorder={this.state.goalBorder}
            />

            <FitnessSituationComponent
              onFitnessSituationChange={this.handleFitnessSituationChange}
              fitnessSituationBorder={this.state.fitnessSituationBorder}
            />

            <WhenStartComponent
              onWhenStartChange={this.handleWhenStartChange}
              whenStartBorder={this.state.whenStartBorder}
            />

            <WhatExtraHelpComponent
              onWhatExtraHelpChange={this.handleWhatExtraHelpChange}
              extraHelpBorder={this.state.extraHelpBorder}
            />

            <GoalTimelineComponent
              onGoalTimelineChange={this.handleGoalTimelineChange}
              goalTimelineBorder={this.state.goalTimelineBorder}
            />

            <DecisionMakerComponent
              onDecisionMakerChange={this.handleDecisionMakerChange}
              decisionMakerBorder={this.state.decisionMakerBorder}
            />
            <FinancialSituationComponent
              onFinancialSituationChange={this.handleFinancialSituationChange}
              financialSituationBorder={this.state.financialSituationBorder}
            />

            <PreferredTrainerGenderComponent
              onPreferredTrainerGenderChange={this.handlePreferredTrainerGenderChange}
              preferredTrainerGenderBorder={this.state.preferredTrainerGenderBorder}
            />

            <AnythingElseToShareComponent
              onAnythingElseToShareChange={this.handleAnythingElseToShareChange}
              anythingElseToShareBorder={this.state.anythingElseToShareBorder}
            />

            <UserNameComponent
              onUserNameChange={this.handleUserNameChange}
              userBorder={this.state.userBorder}
            />
            <UserEmailComponent
              onUserEmailChange={this.handleUserEmailChange}
              emailBorder={this.state.emailBorder}
            />
            <UserMobileComponent
              onUserMobileChange={this.handleUserMobileChange}
              mobileBorder={this.state.mobileBorder}
            />
          </div>
          <div>
            <input
              type="hidden"
              id="custom_6"
              name="custom_6"
              className="js_kartra_santitation"
              data-santitation-type="name"
              aria-label="Preferred Gym"
              value="Club Bunker Newstead"
            />
          </div>

          <div style={{ marginLeft: "15px", marginRight: "15px" }}>
            <FormButtonSecondary
              style={{ alignItems: "center", justifyContent: "center" }}
              type="submit"
              enabled={
                this.state.validName &&
                this.state.validEmail &&
                this.state.validMobile &&
                this.state.validGoal &&
                this.state.validFinancialSituation &&
                this.state.validWhenStart &&
                this.state.validDecisionMaker &&
                this.state.validFinancialSituation
              }>
              Schedule Free Consultation
            </FormButtonSecondary>
          </div>
        </form>
      </div>
    )
  }
}
